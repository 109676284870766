body {
    font-family: 'Courier New', Courier, monospace;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
  }
  
  header {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
  }
  
  h1 {
    margin: 0;
    font-size: 36px;
    letter-spacing: 4px;
  }
  
  .blog-container {
    max-width: 800px;
    margin: 20px auto;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .blog {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  }
  
  .blog:last-child {
    border: none;
  }
  
  .blog h2 {
    margin: 0;
    font-size: 28px;
    color: #333;
  }
  
  .blog p {
    color: #555;
    line-height: 1.6;
  }
  
  .blog a {
    text-decoration: none;
    color: #007BFF;
  }
  
  .blog a:hover {
    text-decoration: underline;
  }
  