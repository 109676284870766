.outerContainer {
    background-color: black;
    padding-left: 10%;
    height: 100vh;
    padding-right: 10%;
    overflow: auto;
}

.outerContainer2 { 
    display: flex;
    flex-direction: row;
}

.upperText {
    color: white;
    font-weight: bolder;
    font-size: 30px;
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
}
.nameText {
    color: white;
    font-weight: bolder;
    font-size: 3em;
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
}

.smallText {
    color: white;
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
    font-size: 1.1em;
    margin-top: 10px;
}

.left {
    padding-top: 10%;
    width: 60%;
}
.dotBig {
    font-size: 60px;
    color: #FD461A;
  }
  a {
    color: #007bff; /* Change to your desired color */
    text-decoration: none; /* Remove underline */
  }
.icons .socialIcons {
    margin-right: 10px;
}
@media (max-width: 1000px) {
    .icons {
        margin-top: 20px;
        padding-bottom: 20px;
    }
    .left {
        width: 90%;
    }
}


