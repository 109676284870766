.typographymehul {
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
    font-weight: bolder;
    color: black;
    font-size: x-large;
    text-align: left;
    align-items: flex-start;
  }
  
  .dot {
    font-size: xx-large;
    color: #FD461A;
  }
  .items {
    justify-content: space-between;
  }
  .navitems {
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    color: black;
    font-size: larger;
  }
  
  /* Style the anchor links */
  .navitems a {
    text-decoration: none; /* Remove underline from the links */
    color: black;
    padding: 5px 10px; /* Add padding to the links for better spacing */
    border-radius: 5px; /* Add rounded corners to the links */
    transition: background-color 0.3s; /* Add smooth transition effect for hover */
  }
  .typographymehul a {
    text-decoration: none; /* Remove underline from the links */
    color: black;
    padding: 5px 10px; /* Add padding to the links for better spacing */
    border-radius: 5px; /* Add rounded corners to the links */
    transition: background-color 0.3s; /* Add smooth transition effect for hover */
  }
  /* Style the anchor links on hover */
  .navitems a:hover {
    background-color: #eee; /* Change background color on hover */
  }
  .navBarRightItems {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  