.outer {
    height: 500px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #121212;
}

.Heading1 {
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
    font-size: 70px;
    font-weight: bolder;
    margin-bottom: 30px;
}
.Heading2 {
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
    font-size: 50px;
    font-weight: bolder;
}
.Mehul {
    color: white;
}
.centerdot {
  color: #FD461A ;
}
.bsp {
    color: #5461FF;
}

.product
{
  font-size: 50px;
  background-image: linear-gradient(
    -225deg,
    white 0%,
    white 29%,
    white 67%,
    #FD461A 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}