body {
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
}
.card {
    max-width: 100%;
    border: 1px solid;
    border-radius: 12px;
    border-color: #cfd8dc;
}
.grid :hover {
    transform: scale(1.005);
}
.typo {
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
    font-size: 30px;
    margin-bottom: 5px;
    color: #5461FF
}
.typo2 {
    font-family: 'Alice', serif;
    font-family: 'Merriweather', serif;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: black;
    text-align: left;
}